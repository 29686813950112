
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IOfferOption } from '@alao-frontend/core'
import OfferOptionsItemBase from '~/components/offers/OfferOptions/OfferOptionsItemBase.vue'
import IconPlus from '~/assets/icons/icon-plus.svg?inline'

@Component({
  components: {
    OfferOptionsItemBase,
    IconPlus,
  },
})
export default class OfferOptionsItemBenefit extends Vue {
  @Prop({
    required: false,
    default: 'transparent',
    type: String,
  })
  readonly bgColor!: string

  @Prop({
    required: false,
    default: 'md',
    type: String,
  })
  readonly size!: ['md', 'lg']

  @Prop({
    required: false,
    default: '#444444',
    type: String,
  })
  readonly textColor!: string

  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  readonly bordered!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly highlightBold!: boolean

  @Prop({ type: Object })
  readonly item?: IOfferOption
}
