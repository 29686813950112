
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconStar from '~/assets/icons/icon-star.svg?inline'
import { AppProviderRatingProps } from '~/components/AppProviderRating/types'
import { providerRatingMap } from '~/core/config/providers'

import type { Theme } from '~/types/theme'

@Component({
  components: {
    IconStar,
  },
})
export default class AppProviderRating extends Vue {
  @Prop({
    required: true,
    type: [Number, String],
  })
  providerId!: AppProviderRatingProps['providerId']

  @Prop({
    type: String,
    required: false,
    default: 'md',
  })
  readonly size!: ['md', 'lg']

  @Prop({
    required: false,
    validator: (value: any) => {
      return value === null || !isNaN(parseFloat(value))
    },
  })
  rating!: AppProviderRatingProps['rating']

  @Prop({
    type: String,
    required: true,
  })
  readonly logoUrl!: AppProviderRatingProps['logoUrl']

  @Prop({
    type: String,
    required: true,
  })
  readonly providerName!: AppProviderRatingProps['providerName']

  @Prop({ type: Number, required: false })
  readonly reviews!: AppProviderRatingProps['reviews']

  @Prop({ type: String, required: false, default: 'row' })
  readonly direction!: AppProviderRatingProps['direction']

  @Prop({ type: Boolean, required: false })
  readonly withBackground!: boolean

  @Prop({ type: String, required: false, default: 'light' })
  readonly theme!: Theme

  @Prop({ type: String, required: true })
  readonly providerSlug!: string

  get filledStarCount (): number {
    return Math.ceil(Number(this.innerRating))
  }

  get innerRating () {
    if (this.rating) {
      return this.rating
    }

    return providerRatingMap[this.providerSlug].rating
  }

  get innerReviews () {
    if (this.reviews) {
      return this.reviews
    }

    return providerRatingMap[this.providerSlug].reviews
  }
}
